<template>
  <div class="main">
    <p style="color:#ccc">海明威曾写过一个谎言</p>
    <p style="color:#ccc">“我把渔网拿去捕鱼可以吗”</p>
    <p style="color:#ccc">“当然”</p>
    <p style="color:#ccc">然而老人和孩子其实都知道</p>
    <p style="color:#ccc">已经没有网了</p>
    <br/>
    <hr style="color:grey"/>
    <br/>
    <p style="color:grey">404啦，请检查您的URL或者联系泡泡</p>
    <br/>
    <p style="color:grey">18238264105</p>
    <p style="color:grey">1119461672@qq.com</p>
  </div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>
body {
  background-color: #444;
  height: 100%;
  width: 100%;
}

.main {
  height: 100%;
  width: 100%;
  padding-top: 30%;
  text-align: center;
  background-color: #444;
}

p {
  font-size: 16px;
}
</style>